<template>
	<div v-if="remoteCalledStream && remoteCalledStream.stream  && !loadingRemote">
		<div style="display: flex; flex-direction: column">
			<video
				:srcObject.prop="remoteCalledStream.stream"
				id="testsocketID"
				autoplay
				playsinline
				webkit-playsinline
				style="width: 200px; height: 100%; padding: 3px; max-height: 250px; background-color: #b4b4b4;"
				class="video-element-sosclick-stream">
			</video>
		</div>
		<div class="mt-0">
			<CButton
				color="primary"
				class="w-100 custom-button-multiple-videocall d-flex justify-content-around align-items-center py-2"
				variant="outline"
				style="flex: 1;"
				@click="startVideoCall()"
			>
				<CIcon name="cilMobile" class="icons-svg" style="width: 1.5rem!important;height: 1.5rem!important;"/> Video Llamada
			</CButton>
			<CButtonClose style="font-size: 40px;position: absolute;top: 10px;right: 10px;font-weight: 400;z-index: 9999;" @click="closePreview()"/>
		</div>
	</div>
	<div v-else>
		<div class="d-flex justify-content-center align-items-center flex-column h-100 px-3 py-2">
			<CButtonClose style="font-size: 20px;position: absolute;top: 2px;right: 10px;font-weight: 400;z-index: 9999;" @click="closePreview()"/>
			<CSpinner
				style="width:2rem;height:2rem;"
				color="#3c4b64"
				grow
			/>
			<p v-if="timerConnectionInterval >= 0">Esperando video del usuario ({{timerConnectionInterval}})</p>
			<div v-else >
				<p style="padding-top:16px;">El usuario no se ha conectado a la videollamada</p>
				<div style="display:flex; justify-content:center; widht:100%;" >
				<CButton @click="cancelVideoCall">cancelar</CButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {completeComplaintAdding} from '../../api/complaints'
export default {
  name: 'VideoElementMultiple',
	props: {
		remoteCalledStream: {
			type: MediaStream
		},
		loadingRemote: {
			type: Boolean,
			default: true
		},
		timerConnectionInterval: {
			type: Number,
			default: 20
		},
		closePreview: {
			type: Function,
			required: true
		},
		startVideoCall: {
			type: Function,
			required: true
		},
		videoCallData: {
    		type: Object,
			required: true
      	}
	},
	watch: {
		loadingRemote(data) {
			if(!data){
				this.showVideo = true
				setTimeout(() => {
					const domVideoElement = document.getElementById('testsocketID')
					domVideoElement.muted = true
					domVideoElement.play()
				}, 500);
			}
		}
	},
  data(){
    return {
			showVideo: false,
      componentStatus: false
    }
  },
  mounted(){

  },
  methods: {
    setDateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    toggleStatus(){
      this.componentStatus = !this.componentStatus
    },
	cancelVideoCall() {
		this.closePreview();
		const data = {complaint_code: this.videoCallData.unique_id}
		completeComplaintAdding(data)
	}
  }
}
</script>

<style scoped>
	.custom-button-multiple-videocall {
			border-color: #CBCBCB;
			color: #666666;
			height: 45px;
	}
	.custom-button-multiple-videocall:hover{
		color: white;
	}
</style>