import { render, staticRenderFns } from "./AudioSettings.vue?vue&type=template&id=a384578e&scoped=true&"
import script from "./AudioSettings.vue?vue&type=script&lang=js&"
export * from "./AudioSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a384578e",
  null
  
)

export default component.exports