<template>
  <div class="w-100 p-0 map-container-dashboard">
    <!--     Configuración Audio -->
    <CModal
      title="Ajustes de audio"
      color="primary"
      :show.sync="$store.state.modalAudioSettings"
      class="modal-alerta modal-audio"
      style="z-index: 9999999999999 !important; position: absolute"
    >
      <AudioSettings v-if="$store.state.modalAudioSettings" />
    </CModal>

    <CModal
      class="modal-delete"
      style="max-width: 500px !important"
      centered
      title="Borrar denuncias activas"
      color="warning"
      :show.sync="$store.state.modalDeleteAllComplaints"
      size="sm"
    >
      ¿Seguro que quieres eliminar todas las denuncias activas?
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="red"
          variant="outline"
          square
          @click="removeAllComplaints"
        >
          Eliminar
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="toggleDeleteAllComplaintsModal"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <GmapMap
      ref="map"
      :center="$store.state.mapCenter.center"
      :zoom="$store.state.mapCenter.zoom"
      :options="{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
      }"
      class="map-container"
      :style="'height: ' + containerHeight + 'px'"
    >
      <GmapHeatmap :data="heatData" :options="{ dissipating: false }" />

      <div v-for="(m, index) in clusterMarkers" :key="index">
        <GmapMarker
          v-if="m?.fixed"
          :key="index"
          :ref="index"
          :position="{
            lat: Number(m.location.latitude),
            lng: Number(m.location.longitude),
          }"
          :clickable="true"
          :icon="{ url: require('../../public/img/fixedMarker.png') }"
          @click="
            currentMarkerId != null ? (currentMarkerId = null) : openHomeMarkerInfo(m)
          "
        >
          <GmapInfoWindow v-if="m.id == currentHomeMarkerId" style="overflow: visible">
            <div class="FixedCard" style="position: relative">
              <p
                @click="currentHomeMarkerId = null"
                style="
                  position: absolute;
                  top: 8px;
                  right: 16px;
                  font-size: 24px;
                  font-weight: 500;
                  cursor: pointer;
                "
              >
                x
              </p>
              <div class="FixedCard__Header">
                <img
                  class="FixedCard__Header__Img"
                  :src="
                    userinfo.profile?.avatar != null
                      ? userinfo.profile?.avatar_link
                      : require('../assets/icons/user.png')
                  "
                  alt="avatar"
                />
                <div class="FixedCard__Header__Data">
                  <h4 class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.name }} {{ userinfo.profile?.lastname }}
                  </h4>
                  <p class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.phone }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.address }}
                  </p>
                </div>
              </div>

              <div class="FixedCard__Actions">
                <h5 class="FixedCard__Actions__Title">
                  <span>Acciones</span>
                  <div class="title-line"></div>
                </h5>
                <div class="FixedCard__Actions__Buttons">
                  <CDropdown color="primary">
                    <template #toggler>
                      <CHeaderNavLink
                        style="color: #000; text-decoration: none !important"
                      >
                        <button
                          :disabled="!userinfo?.devices.length"
                          class="FixedCard__Actions__Buttons__Button"
                        >
                          <img
                            style="width: 24px"
                            src="../assets/imgicons/ICONOS SOSRecurso 10@2x.png"
                            alt=""
                          />
                          <p style="margin: 0">Iniciar Videollamada</p>
                        </button>
                      </CHeaderNavLink>
                    </template>
                    <CDropdownItem
                      style="min-width: 207px"
                      v-for="device in userinfo?.devices"
                      :key="device.id"
                      @click="callHomeMarker(device?.id)"
                      ><span style="font-weight: 600; width: 100%"
                        >{{ device?.brand }} - {{ device?.model }}</span
                      ></CDropdownItem
                    >
                  </CDropdown>
                </div>
              </div>
            </div>
          </GmapInfoWindow>
        </GmapMarker>

        <GmapMarker
          v-else-if="m?.type == 'home'"
          :visible="showUsersAddress"
          customInfo="Address"
          :position="{
            lat: Number(m.location.latitude),
            lng: Number(m.location.longitude),
          }"
          :icon="{
            url: require('../assets/imgicons/home_marker.png'),
            scaledSize: { width: 45, height: 45 },
          }"
          @click="
            currentHomeMarkerId != null
              ? (currentHomeMarkerId = null)
              : openHomeMarkerInfo(m)
          "
        >
          <GmapInfoWindow
            v-if="m.user_id == currentHomeMarkerId"
            style="overflow: visible"
          >
            <div class="FixedCard" style="position: relative">
              <p
                @click="currentHomeMarkerId = null"
                style="
                  position: absolute;
                  top: 8px;
                  right: 16px;
                  font-size: 24px;
                  font-weight: 500;
                  cursor: pointer;
                "
              >
                x
              </p>
              <div class="FixedCard__Header">
                <img
                  class="FixedCard__Header__Img"
                  :src="
                    userinfo.profile?.avatar != null
                      ? userinfo.profile?.avatar_link
                      : require('../assets/icons/user.png')
                  "
                  alt="avatar"
                />
                <div class="FixedCard__Header__Data">
                  <h4 class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.name }} {{ userinfo.profile?.lastname }}
                  </h4>
                  <p class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.phone }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.address }}
                  </p>
                </div>
              </div>

              <div class="FixedCard__Actions">
                <h5 class="FixedCard__Actions__Title">
                  <span>Acciones</span>
                  <div class="title-line"></div>
                </h5>
                <div class="FixedCard__Actions__Buttons">
                  <CDropdown color="primary">
                    <template #toggler>
                      <CHeaderNavLink style="color: #000">
                        <button
                          :disabled="!userinfo?.devices.length"
                          class="FixedCard__Actions__Buttons__Button"
                        >
                          <img
                            style="width: 24px"
                            src="../assets/imgicons/ICONOS SOSRecurso 10@2x.png"
                            alt=""
                          />
                          <p style="margin: 0">Iniciar Videollamada</p>
                        </button>
                      </CHeaderNavLink>
                    </template>
                    <CDropdownItem
                      style="min-width: 207px"
                      v-for="device in userinfo.devices"
                      :key="device.id"
                      @click="callHomeMarker(device.id)"
                      ><span style="font-weight: 600; width: 100%"
                        >{{ device.brand }} - {{ device.model }}</span
                      ></CDropdownItem
                    >
                  </CDropdown>
                </div>
              </div>
            </div>
          </GmapInfoWindow>
        </GmapMarker>

        <GmapMarker
          v-else
          :key="'b' + index"
          :ref="'b' + index"
          :position="{
            lat: Number(m.location.latitude),
            lng: Number(m.location.longitude),
          }"
          :clickable="true"
          :icon="{
            url: returnIcon(m),
            scaledSize: { width: 40, height: 68 },
            markerInfo: m,
          }"
          @click="startMonitorFromAlert(m)"
          @mouseout="outCluster"
          @mouseover="inCluster"
        />
      </div>

      <GmapCluster
        ref="markercluster"
        :styles="clusterStyles"
        @click="clusterClick"
        :averageCenter="true"
        :zoomOnClick="true"
        :ignoreHidden="true"
        @mouseout="outCluster"
        @mouseover="inCluster"
      >
        <div v-if="showComplaints && showCluster">
          <GmapMarker
            :key="index"
            :ref="index"
            v-for="(m, index) in denuncias"
            :position="{
              lat: Number(m.location.latitude),
              lng: Number(m.location.longitude),
            }"
            :clickable="true"
            :icon="{
              url: returnIcon(m),
              scaledSize: { width: 40, height: 68 },
              markerInfo: m,
            }"
            :title="m.type && m.type.title"
            @click="startMonitorFromAlert(m)"
          >
            <VideoCallRoomMultiple
              v-if="
                m.videocall &&
                m.videocall.socket_url &&
                m.videocall.socket_room &&
                !$store.state.videocallSoloConnected
              "
              :videoCallData="m"
            />
          </GmapMarker>
        </div>

        <div v-if="showComplaints && showCluster">
          <GmapMarker
            :key="index"
            :ref="index"
            v-for="(m, index) in $store.state.complaintsInMap"
            :position="{
              lat: Number(m.location.latitude),
              lng: Number(m.location.longitude),
            }"
            :clickable="true"
            :icon="{
              url: returnIcon(m),
              scaledSize: { width: 40, height: 68 },
              markerInfo: { ...m },
            }"
            :title="m.type && m.type.title"
            @click="openComplaintInfo(m)"
          />
        </div>

        <div v-if="showFixedMarkers && showCluster" style="position: relative">
          <GmapMarker
            :key="index"
            :ref="index"
            v-for="(m, index) in fixedDevices"
            :position="{
              lat: Number(m.location.latitude),
              lng: Number(m.location.longitude),
            }"
            :clickable="true"
            :icon="{ url: require('../../public/img/fixedMarker.png'), markerInfo: m }"
            @click="currentMarkerId != null ? (currentMarkerId = null) : openFixedInfo(m)"
          >
            <GmapInfoWindow v-if="m.id == currentMarkerId">
              <div class="FixedCard" style="position: relative">
                <p
                  @click="currentMarkerId = null"
                  style="
                    position: absolute;
                    top: 8px;
                    right: 16px;
                    font-size: 24px;
                    font-weight: 500;
                    cursor: pointer;
                  "
                >
                  x
                </p>
                <div class="FixedCard__Header">
                  <img
                    class="FixedCard__Header__Img"
                    :src="
                      m.user_profile?.avatar != null
                        ? m.user_profile?.avatar
                        : require('../assets/icons/user.png')
                    "
                    alt="avatar"
                  />
                  <div class="FixedCard__Header__Data">
                    <h4 class="FixedCard__Header__Data__Item">
                      {{ m.user_profile?.name }} {{ m.user_profile?.lastname }}
                    </h4>
                    <p class="FixedCard__Header__Data__Item">
                      Dispositivo {{ m?.brand }} {{ m?.name }}
                    </p>
                    <p class="FixedCard__Header__Data__Item">
                      {{ m.user_profile?.phone }}
                    </p>
                    <p class="FixedCard__Header__Data__Item">
                      {{ m.user_profile?.address }}
                    </p>
                  </div>
                </div>

                <div class="FixedCard__Actions">
                  <h5 class="FixedCard__Actions__Title">
                    <span>Acciones</span>
                    <div class="title-line"></div>
                  </h5>
                  <div class="FixedCard__Actions__Buttons">
                    <div
                      class="FixedCard__Actions__Buttons__Button"
                      @click="callFixedDevice"
                    >
                      <img
                        style="width: 24px"
                        src="../assets/imgicons/ICONOS SOSRecurso 10@2x.png"
                        alt=""
                      />
                      <p style="margin: 0">Iniciar Videollamada</p>
                    </div>
                  </div>
                </div>
              </div>
            </GmapInfoWindow>
          </GmapMarker>
        </div>

        <div v-if="showUsersAddress && showCluster">
          <GmapMarker
            :key="index"
            :ref="index"
            v-for="(m, index) in usersAddress"
            :visible="showUsersAddress"
            customInfo="Address"
            :position="m.address_geocoding"
            :icon="{
              url: require('../assets/imgicons/home_marker.png'),
              type: m.type,
              markerInfo: { ...m },
              scaledSize: { width: 45, height: 45 },
            }"
            @click="
              currentMarkerId != null ? (currentMarkerId = null) : openHomeMarkerInfo(m)
            "
          >
            <GmapInfoWindow
              v-if="m.user_id == currentHomeMarkerId"
              style="overflow: visible"
            >
              <div class="FixedCard" style="position: relative">
                <p
                  @click="currentHomeMarkerId = null"
                  style="
                    position: absolute;
                    top: 8px;
                    right: 16px;
                    font-size: 24px;
                    font-weight: 500;
                    cursor: pointer;
                  "
                >
                  x
                </p>
                <div class="FixedCard__Header">
                  <img
                    class="FixedCard__Header__Img"
                    :src="
                      userinfo.profile?.avatar != null
                        ? userinfo.profile?.avatar_link
                        : require('../assets/icons/user.png')
                    "
                    alt="avatar"
                  />
                  <div class="FixedCard__Header__Data">
                    <h4 class="FixedCard__Header__Data__Item">
                      {{ userinfo.profile?.name }} {{ userinfo.profile?.lastname }}
                    </h4>
                    <p class="FixedCard__Header__Data__Item">
                      {{ userinfo.profile?.phone }}
                    </p>
                    <p class="FixedCard__Header__Data__Item">
                      {{ userinfo.profile?.address }}
                    </p>
                  </div>
                </div>

                <div class="FixedCard__Actions">
                  <h5 class="FixedCard__Actions__Title">
                    <span>Acciones</span>
                    <div class="title-line"></div>
                  </h5>
                  <div class="FixedCard__Actions__Buttons">
                    <CDropdown color="primary">
                      <template #toggler>
                        <CHeaderNavLink style="color: #000">
                          <button
                            :disabled="!userinfo?.devices.length"
                            class="FixedCard__Actions__Buttons__Button"
                          >
                            <img
                              style="width: 24px"
                              src="../assets/imgicons/ICONOS SOSRecurso 10@2x.png"
                              alt=""
                            />
                            <p style="margin: 0">Iniciar Videollamada</p>
                          </button>
                        </CHeaderNavLink>
                      </template>
                      <CDropdownItem
                        style="min-width: 207px"
                        v-for="device in userinfo.devices"
                        :key="device.id"
                        @click="callHomeMarker(device.id)"
                        ><span style="font-weight: 600; width: 100%"
                          >{{ device.brand }} - {{ device.model }}</span
                        ></CDropdownItem
                      >
                    </CDropdown>
                  </div>
                </div>
              </div>
            </GmapInfoWindow>
          </GmapMarker>
        </div>
      </GmapCluster>

      <!-- Sin Cluster -->

      <div v-if="showComplaints && !showCluster">
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in denuncias"
          :position="{
            lat: Number(m.location.latitude),
            lng: Number(m.location.longitude),
          }"
          :clickable="true"
          :icon="{
            url: returnIcon(m),
            scaledSize: { width: 40, height: 68 },
            markerInfo: m,
          }"
          :title="m.type && m.type.title"
          @click="startMonitorFromAlert(m)"
        >
          <VideoCallRoomMultiple
            v-if="
              m.videocall &&
              m.videocall.socket_url &&
              m.videocall.socket_room &&
              !$store.state.videocallSoloConnected
            "
            :videoCallData="m"
          />
        </GmapMarker>
      </div>

      <div v-if="showComplaints && !showCluster">
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in $store.state.complaintsInMap"
          :position="{
            lat: Number(m.location.latitude),
            lng: Number(m.location.longitude),
          }"
          :clickable="true"
          :icon="{
            url: returnIcon(m),
            scaledSize: { width: 40, height: 68 },
            markerInfo: { ...m },
          }"
          :title="m.type && m.type.title"
          @click="openComplaintInfo(m)"
        />
      </div>

      <div v-if="showFixedMarkers && !showCluster" style="position: relative">
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in fixedDevices"
          :position="{
            lat: Number(m.location.latitude),
            lng: Number(m.location.longitude),
          }"
          :clickable="true"
          :icon="{ url: require('../../public/img/fixedMarker.png'), markerInfo: m }"
          @click="currentMarkerId != null ? (currentMarkerId = null) : openFixedInfo(m)"
        >
          <GmapInfoWindow v-if="m.id == currentMarkerId">
            <div class="FixedCard" style="position: relative">
              <p
                @click="currentMarkerId = null"
                style="
                  position: absolute;
                  top: 8px;
                  right: 16px;
                  font-size: 24px;
                  font-weight: 500;
                  cursor: pointer;
                "
              >
                x
              </p>
              <div class="FixedCard__Header">
                <img
                  class="FixedCard__Header__Img"
                  :src="
                    m.user_profile?.avatar != null
                      ? m.user_profile?.avatar
                      : require('../assets/icons/user.png')
                  "
                  alt="avatar"
                />
                <div class="FixedCard__Header__Data">
                  <h4 class="FixedCard__Header__Data__Item">
                    {{ m.user_profile?.name }} {{ m.user_profile?.lastname }}
                  </h4>
                  <p class="FixedCard__Header__Data__Item">
                    Dispositivo {{ m?.brand }} {{ m?.name }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ m.user_profile?.phone }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ m.user_profile?.address }}
                  </p>
                </div>
              </div>

              <div class="FixedCard__Actions">
                <h5 class="FixedCard__Actions__Title">
                  <span>Acciones</span>
                  <div class="title-line"></div>
                </h5>
                <div class="FixedCard__Actions__Buttons">
                  <div
                    class="FixedCard__Actions__Buttons__Button"
                    @click="callFixedDevice"
                  >
                    <img
                      style="width: 24px"
                      src="../assets/imgicons/ICONOS SOSRecurso 10@2x.png"
                      alt=""
                    />
                    <p style="margin: 0">Iniciar Videollamada</p>
                  </div>
                </div>
              </div>
            </div>
          </GmapInfoWindow>
        </GmapMarker>
      </div>

      <div v-if="showUsersAddress && !showCluster">
        <!--         <GmapCluster
          gridSize="0"
          ref="markercluster"
          :styles="clusterStyles2"
          @click="clusterClick"
          :averageCenter="true"
          :zoomOnClick="true"
          :ignoreHidden="true"
          @mouseout="outCluster"
          @mouseover="inCluster"
        > -->
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in usersAddress"
          :visible="showUsersAddress"
          customInfo="Address"
          :position="m.address_geocoding"
          :icon="{
            url: require('../assets/imgicons/home_marker.png'),
            scaledSize: { width: 45, height: 45 },
          }"
          @click="
            currentMarkerId != null ? (currentMarkerId = null) : openHomeMarkerInfo(m)
          "
        >
          <GmapInfoWindow
            v-if="m.user_id == currentHomeMarkerId"
            style="overflow: visible"
          >
            <div class="FixedCard" style="position: relative">
              <p
                @click="currentHomeMarkerId = null"
                style="
                  position: absolute;
                  top: 8px;
                  right: 16px;
                  font-size: 24px;
                  font-weight: 500;
                  cursor: pointer;
                "
              >
                x
              </p>
              <div class="FixedCard__Header">
                <img
                  class="FixedCard__Header__Img"
                  :src="
                    userinfo.profile?.avatar != null
                      ? userinfo.profile?.avatar_link
                      : require('../assets/icons/user.png')
                  "
                  alt="avatar"
                />
                <div class="FixedCard__Header__Data">
                  <h4 class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.name }} {{ userinfo.profile?.lastname }}
                  </h4>
                  <p class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.phone }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ userinfo.profile?.address }}
                  </p>
                </div>
              </div>

              <div class="FixedCard__Actions">
                <h5 class="FixedCard__Actions__Title">
                  <span>Acciones</span>
                  <div class="title-line"></div>
                </h5>
                <div class="FixedCard__Actions__Buttons">
                  <CDropdown color="primary">
                    <template #toggler>
                      <CHeaderNavLink style="color: #000">
                        <button
                          :disabled="!userinfo?.devices.length"
                          class="FixedCard__Actions__Buttons__Button"
                        >
                          <img
                            style="width: 24px"
                            src="../assets/imgicons/ICONOS SOSRecurso 10@2x.png"
                            alt=""
                          />
                          <p style="margin: 0">Iniciar Videollamada</p>
                        </button>
                      </CHeaderNavLink>
                    </template>
                    <CDropdownItem
                      style="min-width: 207px"
                      v-for="device in userinfo.devices"
                      :key="device.id"
                      @click="callHomeMarker(device.id)"
                      ><span style="font-weight: 600; width: 100%"
                        >{{ device.brand }} - {{ device.model }}</span
                      ></CDropdownItem
                    >
                  </CDropdown>
                </div>
              </div>
            </div>
          </GmapInfoWindow>
        </GmapMarker>
        <!--         </GmapCluster> -->
      </div>
    </GmapMap>

    <div style="position: absolute; top: 30px; left: 30px; z-index: 99">
      <CButton
        v-c-tooltip="'Acercar Mapa'"
        class="mx-1"
        color="success"
        @click="changeZoom()"
      >
        <font-awesome-icon icon="plus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Alejar Mapa'"
        class="mx-1"
        color="primary"
        @click="changeZoom(1)"
      >
        <font-awesome-icon icon="minus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Ver mapa calor alertas'"
        :color="showHeatMap ? 'success' : 'primary'"
        class="mx-1"
        @click="toggleHeatMap()"
      >
        <CSpinner style="width: 20px; height: 20px" color="white" v-if="loadingHeatMap" />
        <font-awesome-icon v-else icon="fire-alt" style="font-size: 20px" />
      </CButton>

      <CButton
        color="primary"
        v-c-tooltip="'Centrar Mapa'"
        class="mx-1"
        @click="centerMapDefault"
      >
        <img
          src="../assets/imgicons/ICONOS SOSRecurso 11@2x.png"
          style="width: 20px; height: 20px"
          alt=""
        />
      </CButton>
    </div>

    <div
      class="filterContainer"
      :class="FiltersOpen ? 'opened' : 'closed'"
      @mouseover="timeShowFilters = 999999"
      @mouseleave="timeShowFilters = 3000"
    >
      <div style="overflow: hidden; display: flex">
        <div
          :style="[FiltersOpen ? { opacity: '1' } : { visibility: 'hidden' }]"
          style="
            transition: all 0.7s ease-out;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-weight: 500;
            position: relative;
          "
        >
          <img
            @click="showFixedMarkers = !showFixedMarkers"
            :style="[showFixedMarkers ? { opacity: '1' } : { opacity: '.7' }]"
            style="width: 82px; cursor: pointer"
            src="../assets/icons/fixedMarker.png"
            alt=""
          />
          <p style="margin: 0; margin-bottom: 6px">Puntos Fijos</p>
          <div class="badge badge-markers" style="background-color: #243580">
            <span v-if="!loader">{{ fixedDevices.length }}</span>
            <div v-else class="loader"></div>
          </div>
        </div>

        <!--        <CButton class="mx-0 p-0" @click="toggleShowOk">
          <CImg
            :style="showOk ? { opacity: '1' } : { opacity: '.6' }"
            class="img"
            width="60"
            :src="require('../assets/imgicons/okmarker.png')"
          />
          <div class="badge badge-markers" style="background-color: #009246">
            <span v-if="!loader">{{ ok.length }}</span>
            <div v-else class="loader"></div>
          </div>
        </CButton> -->

        <div
          :style="[FiltersOpen ? { display: 'flex' } : { visibility: 'hidden' }]"
          style="
            transition: all 0.7s ease-out;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-weight: 500;
            justify-content: end;
            gap: 15px;
            margin-left: 8px;
            position: relative;
          "
        >
          <img
            @click="showComplaints = !showComplaints"
            style="width: 32px; cursor: pointer"
            :style="[showComplaints ? { opacity: '1' } : { opacity: '.7' }]"
            src="../assets/icons/markerDenuncia.png"
          />
          <p style="margin: 0; margin-bottom: 6px">Denuncias</p>
          <div class="badge badge-marker-left" style="background-color: #672d7e">
            <span v-if="!loader">{{ denuncias.length }}</span>
            <div v-else class="loader"></div>
          </div>
          <div class="badge badge-marker-right" style="background-color: #672d7e">
            <span v-if="!loader">{{ $store.state.complaintTypes.length }}</span>
            <div v-else class="loader"></div>
          </div>
        </div>
        <div
          style="
            transition: all 0.7s ease-out;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-weight: 500;
            justify-content: end;
            gap: 15px;
            margin-left: 8px;
            position: relative;
          "
        >
          <img
            @click="showUsersAddress = !showUsersAddress"
            style="width: 52px; cursor: pointer"
            :style="[showUsersAddress ? { opacity: '1' } : { opacity: '.7' }]"
            src="../assets/icons/home_marker.png"
          />
          <p style="margin: 0; margin-bottom: 6px">Domicilios</p>
          <div class="badge badge-markers" style="background-color: #672d7e">
            <span v-if="!loader">{{ usersAddress.length }}</span>
            <div v-else class="loader"></div>
          </div>
        </div>
        <div
          style="
            transition: all 0.7s ease-out;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-weight: 500;
            justify-content: end;
            gap: 15px;
            margin-left: 8px;
          "
        >
          <img
            @click="showCluster = !showCluster"
            style="width: 52px; cursor: pointer"
            :style="[showCluster ? { opacity: '1' } : { opacity: '.7' }]"
            src="../assets/imgicons/cluster80.png"
          />
          <p style="margin: 0; margin-bottom: 6px">Clusters</p>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-pill btn-primary custom-btn-map-controllers"
        @click="FiltersOpen = !FiltersOpen"
      >
        <font-awesome-icon :icon="FiltersOpen ? 'chevron-left' : 'chevron-right'" />
      </button>
    </div>
  </div>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/database";
import { remove, ref, getDatabase } from "firebase/database";
import modalBoton from "./widgets/modalBoton";
import { cameraList, getTuyaCameraVideoInfo } from "../api/cameras";
import { getComplaintsHeatMap, getComplaintsHeatMapByType } from "@/api/complaints";
import { getFixedPanelDevices, callToFixedDevice } from "@/api/devices";
import { getUsersGeo } from "@/api/user";
import { getUserInfo } from "../api/users";

import TrackingMarkers from "./widgets/Tracking/TrackingMarkers";
import MapSoloTracking from "./widgets/Tracking/MapSoloTracking";
import ContentInfo from "./widgets/InfoWindow/ContentInfo";
import GmapHeatmap from "./widgets/Map/Heatmap";
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";
import ModalCameraViewer from "./widgets/TuyaCamerasView/ModalCameraViewer";
import VideoCallRoomMultiple from "../views/videocall/VideoCallRoomMultiple.vue";
import AudioSettings from "./videocall/AudioSettings";

export default {
  name: "Dashboard",
  beforeCreate: function () {
    document.body.className = "home";
  },
  beforeDestroy: function () {
    document.body.classList.remove("home");
  },
  components: {
    modalBoton,
    TrackingMarkers,
    ContentInfo,
    MapSoloTracking,
    ModalCameraViewer,
    GmapHeatmap,
    VideoCallRoomMultiple,
    AudioSettings,
  },
  computed: {
    ...mapGetters(["userData"]),
    google: gmapApi,
    heatData() {
      return (
        this.google &&
        this.heatMapMarkers.map((marker) => {
          return {
            location: new this.google.maps.LatLng({
              lat: marker.latitude,
              lng: marker.longitude,
            }),
            weight: 2,
          };
        })
      );
    },
  },
  data() {
    return {
      FiltersOpen: false,
      showFixedMarkers: false,
      showComplaints: true,
      fixedDevices: [],
      mapControllersOpen: true,
      infoOpen: false,
      infoPos: {
        lat: -33.4373063,
        lng: -70.634297,
      },
      containerHeight: 700,
      centerMap: {
        lat: -33.4373063,
        lng: -70.634297,
      },
      mapZoom: 12,
      path: [],
      drawingPath: [],
      filter: {
        mujeres: true,
        comercio: true,
        hogar: true,
      },
      clusterMarkers: [],
      denuncias: [],
      alertas: [],
      allDevices: [],
      monitores: [],
      personales: [],
      camarasIP: [],
      botonesSOS: [],
      monitorear: null,
      showAlertas: true,
      showMonitores: true,
      showPersonales: true,
      showCamaras: true,
      showBotones: false,
      showModalButton: false,
      modalButtonData: null,
      showEndAlert: false,
      alertData: null,
      animated: false,
      hoverComuna: false,
      admin: null,
      polygonReady: false,
      clusterStyles: [
        {
          textColor: "#000",
          textSize: 12,
          url: require("../assets/imgicons/cluster40.png"),
          objectFit: "contain",
          height: 40,
          width: 40,
        },
        {
          textColor: "#000",
          textSize: 12,
          url: require("../assets/imgicons/cluster40.png"),
          objectFit: "contain",
          height: 40,
          width: 40,
        },
        {
          textColor: "#000",
          textSize: 12,
          url: require("../assets/imgicons/cluster40.png"),
          objectFit: "contain",
          height: 40,
          width: 40,
        },
      ],
      clusterStyles2: [
        {
          textColor: "#000",
          textSize: 1,
          url: require("../assets/imgicons/home_marker.png"),
          objectFit: "cover",
          height: 50,
          width: 50,
        },
        {
          textColor: "#000",
          textSize: 1,
          url: require("../assets/imgicons/home_marker.png"),
          objectFit: "cover",
          height: 50,
          width: 50,
        },
        {
          textColor: "#000",
          textSize: 1,
          url: require("../assets/imgicons/home_marker.png"),
          objectFit: "cover",
          height: 50,
          width: 50,
        },
      ],
      onCluster: false,
      usersInfo: [],
      userinfo: {},
      infoStatus: [],
      viewCameraInfo: false,
      showModalCameraViewer: false,
      heatMapMarkers: [],
      showHeatMap: false,
      loadingHeatMap: false,
      currentMarkerId: null,
      currentHomeMarkerId: null,
      currentMarker: null,
      timeShowFilters: 0,
      centerArica: false,
      usersAddress: [],
      showUsersAddress: true,
      showCluster: false,
      loader: true,
    };
  },
  watch: {
    userData: function (data) {
      console.log("userData: ", data);
    },
    "$store.state.complaintType": function () {
      this.changeHeatMap();
    },
    timeShowFilters: function (data) {
      if (this.FiltersOpen) {
        setTimeout(() => {
          this.FiltersOpen = false;
        }, this.timeShowFilters);
      }
    },
  },
  async mounted() {
    this.timeShowFilters = 3000;
    this.FiltersOpen = true;
    this.admin = this.$store.state.userData;
    this.containerHeight =
      window.innerHeight - document.getElementsByTagName("header")[0].clientHeight;
    this.childAddedListenner();
    this.childRemovedListenner();
    const self = this;
    this.$events.listen("filterSegments", (eventData) => {
      self.filter = eventData;
      self.filterMonitores();
    });
    this.$events.listen("centerMap", (eventData) => {
      self.centerMap = {
        lat: eventData.latitud,
        lng: eventData.longitud,
      };
      self.mapZoom = 18;
    });
    this.getAdminPosition();

    this.getFixedDevices();

    this.getGeoUsers();
  },
  methods: {
    toggleDeleteAllComplaintsModal() {
      this.$store.commit(
        "setModalDeleteAllComplaints",
        !this.$store.state.modalDeleteAllComplaints
      );
    },
    centerMapDefault() {
      if (!this.centerArica) {
        this.$store.commit("setMapCenter", {
          center: { lat: -18.4746, lng: -70.29792 },
          zoom: 12,
        });
        this.centerArica = true;
      } else {
        this.getAdminPosition();
        this.centerArica = false;
      }
    },
    getFixedDevices() {
      getFixedPanelDevices().then((res) => {
        const fixed = [...res.devices].map((device) => {
          device.fixed = true;
          return device;
        });
        this.fixedDevices = fixed;
        this.loader = false;
      });
    },
    getGeoUsers() {
      getUsersGeo().then((res) => {
        this.usersAddress = res?.users.map((user) => {
          user.position = user?.address_geocoding;
          user.type = "home";
          return user;
        });
      });
    },
    callFixedDevice() {
      const data = { device_id: this.currentMarkerId };
      callToFixedDevice(data).then((res) => {
        const videocallData = {
          status: true,
          alertID: null,
          geoUserAlert: null,
          fullView: true,
          deviceID: this.currentMarkerId.id,
          calledUserID: this.currentMarker.user_id,
          socketUrl: res.videocall.socket_url,
          socketRoom: res.videocall.socket_room,
          videocallID: res.videocall.id,
          hideVideo: false,
          silent: true,
        };
        this.$store.commit("setVideoCallData", videocallData);
      });
    },
    callHomeMarker(device_id) {
      const data = { device_id };
      callToFixedDevice(data).then((res) => {
        const videocallData = {
          status: true,
          alertID: null,
          geoUserAlert: null,
          fullView: true,
          deviceID: device_id,
          calledUserID: this.currentHomeMarkerId,
          socketUrl: res.videocall.socket_url,
          socketRoom: res.videocall.socket_room,
          videocallID: res.videocall.id,
          hideVideo: false,
          silent: true,
        };
        this.$store.commit("setVideoCallData", videocallData);
      });
    },
    getAdminPosition() {
      if (this.admin.id <= 7) {
        const options = {
          enableHighAccuracy: true,
          timeout: 10000,
        };
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const center = {
              center: {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
              },
              zoom: 12,
            };
            this.$store.commit("setMapCenter", center);
          },
          null,
          options
        );
      }
    },
    openComplaintInfo(complaint) {
      this.$store.commit("setShowingComplaint", complaint);
    },
    openFixedInfo(fixedInfo) {
      this.currentMarkerId = fixedInfo.id;
      this.currentMarker = fixedInfo;
    },
    openHomeMarkerInfo(homemarker) {
      console.log(3, homemarker);
      this.currentHomeMarkerId = homemarker.user_id;

      getUserInfo({ user_id: homemarker.user_id }).then((res) => {
        const { user } = res;
        this.userinfo = user;
        console.log(4, this.userinfo);
      });
    },
    async changeHeatMap() {
      if (this.showHeatMap) {
        this.loadingHeatMap = true;
        if (this.$store.state.complaintType) {
          const type_id = this.$store.state.complaintType;
          const heatMapMarkers = await getComplaintsHeatMapByType({
            complaint_type_id: type_id,
          });
          this.heatMapMarkers = heatMapMarkers.complaints;
        } else {
          const heatMapMarkers = await getComplaintsHeatMap();
          this.heatMapMarkers = heatMapMarkers.complaints;
        }
        this.loadingHeatMap = false;
      }
    },
    async toggleHeatMap() {
      this.showHeatMap = !this.showHeatMap;
      if (this.showHeatMap) {
        this.loadingHeatMap = true;
        if (this.$store.state.complaintType) {
          const type_id = this.$store.state.complaintType;
          const heatMapMarkers = await getComplaintsHeatMapByType({
            complaint_type_id: type_id,
          });
          this.heatMapMarkers = heatMapMarkers.complaints;
        } else {
          const heatMapMarkers = await getComplaintsHeatMap();
          this.heatMapMarkers = heatMapMarkers.complaints;
        }
        this.loadingHeatMap = false;
      } else {
        this.heatMapMarkers = [];
      }
    },
    closeModalCameraViewer() {
      this.showModalCameraViewer = false;
      this.viewCameraInfo = null;
    },
    async startCameraView(camera) {
      this.viewCameraInfo = camera;
      this.showModalCameraViewer = true;
    },
    requestUserInfo(m) {
      const found = this.usersInfo.findIndex((item) => {
        return item === m.id_usuario;
      });
      if (found === -1) {
        this.usersInfo.push(m.id_usuario);
      }

      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === m.id_usuario;
      });
      if (foundStatus === -1) {
        this.infoStatus.push(m.id_usuario);
      } else {
        this.infoStatus.splice(foundStatus, 1);
      }
    },
    closeContentInfo(m) {
      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === m.id_usuario;
      });
      if (foundStatus !== -1) {
        this.infoStatus.splice(foundStatus, 1);
      }
    },
    botonClick(m) {
      this.showModalButton = true;
      this.modalButtonData = m;
    },
    closeModalButton() {
      this.showModalButton = false;
      this.modalButtonData = null;
    },
    inCluster() {
      this.onCluster = true;
    },
    outCluster() {
      this.onCluster = false;
      const self = this;
      setTimeout(function () {
        if (!self.onCluster) {
          self.clusterMarkers = [];
        }
      }, 3000);
    },
    returnIcon(marker) {
      if (marker.type && marker.type.base_type) {
        return marker.type.base_type.marker_icon;
      } else if (marker.type) {
        return marker.type.marker_icon;
      }
    },
    markerAction(marker) {
      if (marker.camera_id) {
        this.startCameraView(marker);
      } else if (marker.data) {
        this.showModalAlert(marker);
      } else {
        this.setMonitorear(marker);
      }
      this.clusterMarkers = [];
    },
    changeZoom(type = 0) {
      this.$refs.map.$mapPromise.then((map) => {
        const actual = map.getZoom();
        type === 0 ? map.setZoom(actual + 1) : map.setZoom(actual - 1);
      });
    },
    clusterClick(cluster) {
      const mapZoom = cluster.map_.getZoom();
      if (mapZoom === 22) {
        const self = this;
        if (self.clusterMarkers.length > 0) {
          self.clusterMarkers = [];
        } else {
          let radius = 1;
          const markers = cluster.getMarkers();
          const initialPos = markers[0].getPosition();
          markers.map(function (item, index) {
            let degrees = 180 + index * (360 / (8 * radius));
            let marcador = { ...item?.icon?.markerInfo };
            console.log(1, item);
            console.log(1, marcador);
            marcador.location = {
              latitude:
                initialPos.lat() -
                0.0000065 -
                Math.cos(degrees * (Math.PI / 180)) * 0.00002 * radius,
              longitude:
                initialPos.lng() -
                Math.sin(degrees * (Math.PI / 180)) * 0.000025 * radius,
            };
            marcador.type = item.icon.type;
            self.clusterMarkers.push(marcador);
            if (index > 0 && (index + 1) % (8 * radius * Math.pow(2, radius - 1)) === 0) {
              radius++;
            }
          });
        }
      }
    },
    filterMonitores() {
      const self = this;
      this.monitores = this.allDevices.filter(function (item) {
        if (
          self.filter.mujeres &&
          self.filter.comercio &&
          self.filter.hogar &&
          item.deviceData &&
          item.deviceData.is_monitor
        ) {
          return true;
        }
        return (
          item.deviceData &&
          item.deviceData.is_monitor &&
          ((self.filter.mujeres && item.cluster && item.cluster.includes(1)) ||
            (self.filter.comercio && item.cluster && item.cluster.includes(2)) ||
            (self.filter.hogar && item.cluster && item.cluster.includes(3)))
        );
      });
      this.personales = this.allDevices.filter(function (item) {
        if (
          self.filter.mujeres &&
          self.filter.comercio &&
          self.filter.hogar &&
          !(item.deviceData && item.deviceData.is_monitor)
        ) {
          return true;
        }
        return (
          !(item.deviceData && item.deviceData.is_monitor) &&
          ((self.filter.mujeres && item.cluster && item.cluster.includes(1)) ||
            (self.filter.comercio && item.cluster && item.cluster.includes(2)) ||
            (self.filter.hogar && item.cluster && item.cluster.includes(3)))
        );
      });
    },
    toogleMonitor() {
      this.showMonitores = !this.showMonitores;
    },
    toogleCamaras() {
      this.showCamaras = !this.showCamaras;
    },
    toogleAlertas() {
      this.showAlertas = !this.showAlertas;
    },
    tooglePersonales() {
      this.showPersonales = !this.showPersonales;
    },
    toogleBotones() {
      this.showBotones = !this.showBotones;
    },
    async startMonitorFromAlert(data) {
      const calledUserID = data.user_id ? data.user_id : data.videocall.complaint_id;
      this.$store.commit("setVideoCallData", {
        status: true,
        alertID: data.id,
        geoUserAlert: data.location,
        fullView: true,
        calledUserID,
        socketUrl: data.videocall.socket_url,
        socketRoom: data.videocall.socket_room,
        videocallID: data.videocall.id,
        hideVideo: false,
        silent: true,
      });
    },
    setMonitorear(data) {
      data.showVideo = false;
      this.$events.fire("startMonitoreo", data);
    },
    checkIfMonitorInAlert(deviceID) {
      const found = this.alertas.findIndex(function (item, index) {
        return item.data.deviceID === deviceID;
      });
      return found === -1 ? false : true;
    },
    childAddedListenner() {
      const self = globalThis;
      firebase
        .database(this.$store.state.primaryDB)
        .ref(`/inProgress/${process.env.VUE_APP_Company_ID}/`)
        .on("child_added", (snapshot) => {
          let data = snapshot.val();
          this.denuncias.push(data);
        });
    },
    childRemovedListenner() {
      const self = this;
      firebase
        .database(this.$store.state.primaryDB)
        .ref(`/inProgress/${process.env.VUE_APP_Company_ID}/`)
        .on("child_removed", (data) => {
          const denunciaData = data.val();
          const foundIndex = this.denuncias.findIndex((denuncia) => {
            return denuncia.id === denunciaData.id;
          });
          if (foundIndex != -1) {
            let denuncias = [...this.denuncias];
            denuncias.splice(foundIndex, 1);
            this.denuncias = denuncias;
          }
        });
    },
    addAlert(alert) {
      const alertMarker = {
        position: {
          lat: alert.geo_usuario.lat,
          lng: alert.geo_usuario.lng,
        },
        data: alert,
      };
      const center = {
        center: {
          lat: alert.geo_usuario.lat,
          lng: alert.geo_usuario.lng,
        },
        zoom: 18,
      };
      this.$store.commit("setMapCenter", center);
      this.alertas.push(alertMarker);
    },
    showModalAlert(alerta) {
      this.$events.fire("showAlert", alerta.data);
    },
    removeAllComplaints() {
      this.denuncias.forEach((complaint) => {
        firebase
          .database(this.$store.state.primaryDB)
          .ref(
            "/inProgress/" + process.env.VUE_APP_Company_ID + "/" + complaint.unique_id
          )
          .remove();
      });
      this.$store.commit(
        "setModalDeleteAllComplaints",
        !this.$store.state.modalDeleteAllComplaints
      );
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/style.scss";
.home .c-main,
.home .c-main > div {
  padding: 0 !important;
}
.map-container {
  width: 100%;
  height: 700px;
}
.dropclusters {
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.img.gray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.filterContainer {
  background-color: $color-primary-transparent;
  border-radius: 0 15px 15px 0;
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 99;
  display: flex;
  gap: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border: solid 1px #fff;
  transition: width 0.3s ease-out;
}
.opened {
  width: 350px;
}
.closed {
  width: 20px;
}

.FixedCard {
  width: 500px;
  padding: 20px;
  &__Header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    gap: 32px;
    padding-bottom: 16px;
    &__Img {
      border: solid 2px #cccccc;
      width: 100px;
      border-radius: 10px;
      object-fit: cover;
      background-color: #e6ecff;
    }
    &__Data {
      &__Item {
        margin-bottom: 6px;
        font-weight: 400;
      }
    }
  }
  &__Actions {
    margin-top: 16px;
    &__Title {
      display: flex;
      align-items: center;
    }
    &__Buttons {
      display: flex;
      &__Button {
        border: solid 1px #cccccc;
        border-radius: 8px;
        cursor: pointer;
        padding: 16px 18px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        &:hover {
          text-decoration: none;
          background-color: #e7e3e3;
        }
      }
    }
  }
}
.title-line {
  color: red;
  width: 100%;
  border: solid 1px #cccccc;
  margin-left: 8px;
}
.modal-audio {
  z-index: 9999999999999 !important;
}

.c-header-nav-link {
  text-decoration: none !important;
}
/* .gm-style .gm-style-iw {
  overflow: visible;
}

.gm-style .gm-style-iw-c {
  overflow: visible;
}
.gm-style .gm-style-iw-d {
  overflow: visible;
}
.gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  overflow: visible;
} */

.cluster img {
  width: 40px;
}

.badge-markers {
  position: absolute;
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  vertical-align: top;
  right: 8px;
  top: 4px;
}
.badge-marker-left {
  position: absolute;
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  vertical-align: top;
  right: 0;
  top: 4px;
}
.badge-marker-right {
  position: absolute;
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  vertical-align: top;
  left: 0;
  top: 4px;
}
.loader {
  width: 2px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
</style>
