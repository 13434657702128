import { render, staticRenderFns } from "./ModalCameraViewer.vue?vue&type=template&id=485caa80&"
import script from "./ModalCameraViewer.vue?vue&type=script&lang=js&"
export * from "./ModalCameraViewer.vue?vue&type=script&lang=js&"
import style0 from "./ModalCameraViewer.vue?vue&type=style&index=0&id=485caa80&prod&media=screen&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports